/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
:root {
  /* --main-background: #e8e2d1; */
  --main-background: #ffffff;
}
body {
  background-color: var(--main-background);
}
.bg_gray,
footer {
  background-color: var(--main-background) !important;
}
.gray-bg {
  background-color: var(--main-background) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fancy-select {
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 10px;
}

.fancy-select .trigger {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 11px;
  font-weight: normal;
  box-shadow: none !important;
  padding: 12px 30px 12px 25px;
}

.fancy-select .trigger:after {
  content: "\f107";
  font-size: 14px;
  top: 10px;
}

.fancy-select .trigger.open {
  border: 1px solid #ca7379;
}

.fancy-select .options {
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  font-weight: normal;
}
.fancy-select .options li {
  color: #666;
}
.fancy-select .options li:hover {
  color: #ca7379;
}
.fancy-select .options .selected {
  background-color: #ca7379;
  color: #fff;
}
.fancy-select .options .selected:hover {
  color: #fff;
}

.owl-item {
  width: 100px !important;
  display: flex;
  align-items: flex-end;
}
.owl-stage {
  display: flex !important;
  justify-content: space-around !important;
}
.image-gallery {
  padding-bottom: 50px;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(70vh - 80px) !important;
}

.image-gallery-slide .image-gallery-image {
  object-fit: cover !important;
}

.main-img {
  /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.box-product .product-item .group-buttons button {
  border: 1px solid #666;
}

.img-responsive {
  border-radius: 0.5rem;
}

.product-button {
  position: absolute;
  bottom: 20px;
  /* left: 0;
  padding: 0 15px; */
  display: flex;
  justify-content: center;
  z-index: 9;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.product-item > div {
  overflow: hidden;
  display: inline-block;
}
.product-item .product-lable > div:before {
  content: "";
  display: block;
  /* position: absolute;
  left: -150px;
  top: 0; */
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
  animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  opacity: 0;
  visibility: hidden;

  position: absolute;
  background: red;
  right: 3px;
  top: 3px;
  padding: 5px;
  color: white;
  font-size: small;
  z-index: 5;
  border-radius: 0.3rem;
}
.product-lable {
  position: absolute;

  right: 3px;
  top: 3px;
  padding: 5px;
  color: white;
  font-size: smaller;
  z-index: 5;
}

.hot {
  background: #C0040B;
  border-radius: 0.3rem;
  padding: 5px;
}
.outofstock {
  background: #99948E;
  border-radius: 0.3rem;
  padding: 5px;
}